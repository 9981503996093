<template>
    <section class="news">
        <tool-bar v-bind:lang.sync="lang">
            Blog
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-on:click="addNews">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add Blog Article</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!edited"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <article class="message is-dark">
            <div class="message-body" style="background-color: rgba(255, 255, 255, 1)">
                You can drag blog articles below to reorder them. It will be reflected in the main website upon
                saving.
            </div>
        </article>

        <div class="container py-5">
            <draggable v-model="blog"
                       v-on:end="onDragEnd">
                <transition-group>
                    <blog-item v-for="article in blog"
                               v-bind:key="article._id"
                               v-bind:lang="lang"
                               v-bind:article="article">
                    </blog-item>
                </transition-group>
            </draggable>
        </div>
    </section>
</template>

<script>
import draggable from "vuedraggable";
import ToolBar from "../layouts/ToolBar";
import BlogItem from "@/components/page/Blog/BlogItem";

export default {
    name: "Blog",
    components: {
        BlogItem,
        draggable,
        ToolBar
    },
    data() {
        return {
            loading: false,
            edited: false,
            lang: "en",

            blog: []
        }
    },
    computed: {
        blogInStore () {
            return this.$store.state.mall.blog;
        },
    },
    methods: {
        onDragEnd() {
            this.edited = true
        },

        addNews() {
            this.$router.push("/page/blog/new");
        },

        async save () {
            this.loading = true
            console.log(this.blog);
            let result;
            try {
                result = await this.$store
                    .dispatch(
                        "mall/updateMall",
                        {
                            "blog": this.blog.map(({ _id }) => _id)
                        }
                    );
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
        },
    },
    mounted () {
        this.blog = this.blogInStore;
    },
    watch: {
        "blogInStore" (newVal) {
            this.blog = newVal;
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.message {
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.news {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    & > .tabs {
        margin-top: 1rem;
        margin-bottom: 1rem;

        & > ul {
            display: flex;
            justify-content: center;
        }
    }

    & > .container {
        background-color: rgba(255, 255, 255, 1);
    }

    .is-200x200 {
        height: 200px;
        width: 200px;
    }

    article.testimonial {
        border-bottom: solid 1px rgba(113, 113, 113, 1);
    }
}
</style>
