<template>
    <article class="row no-gutters pb-3 mb-3 pt-2">
        <div class="col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
            <img class="mb-3 mr-md-3 item-image is-200x200"
                 v-bind:alt="title[lang]"
                 v-bind:src="thumbnail">
        </div>
        <div class="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <h3 class="mt-0 mb-3 text-center text-md-left"
                v-html="title[lang]">
            </h3>
            <p v-line-clamp:20="3">
                {{ content ? $sanitize(content[lang]) : "" }}
            </p>
            <div class="row no-gutters">
                <div class="col-8"></div>
                <div class="col-4 text-center text-md-right">
                    <router-link type="button" class="btn btn-outline-dark mr-2"
                                 style="background-color: rgba(255, 255, 255, 1); "
                                 v-bind:to="`/page/blog/${ articleId }`">
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </router-link>
                    <button type="button" class="btn btn-outline-danger"
                            v-bind:class="{ 'is-loading': loading }"
                            v-on:click="deleteBlog">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "BlogItem",
    props: {
        "lang": {
            type: String,
            default: "en",
        },

        "article": {
            type: Object,
            required: true,
        },
    },
    data() {
        return {

        };
    },
    computed: {
        articleId () {
            return this.article?._id ?? "";
        },

        title () {
            return this.article?.title ?? "";
        },

        content () {
            return this.article?.content ?? "";
        },

        thumbnail () {
            return this.article?.thumbnail ?
                `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/news/image/${ this.article.thumbnail }` :
                "";
        },
    },
    methods: {
        async deleteBlog () {
            this.loading = true;
            try {
                await this.$store
                    .dispatch(
                        "mall/deleteBlog",
                        this.articleId
                    );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.is-200x200 {
    height: 200px;
    width: 200px;
}

article {
    border-bottom: solid 1px rgba(113, 113, 113, 1);
}
</style>
